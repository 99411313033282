@charset "UTF-8";
/*!
* Start Bootstrap - Simple Sidebar v6.0.3 (https://startbootstrap.com/template/simple-sidebar)
* Copyright 2013-2021 Start Bootstrap
* Licensed under MIT (https://github.com/StartBootstrap/startbootstrap-simple-sidebar/blob/master/LICENSE)
*/
/*!
 * Bootstrap v5.1.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

@font-face {
  font-family: "ConcertOne";
  src: local("ConcertOne"),
    url(./assets/fonts/ConcertOne/ConcertOne-Regular.ttf) format("truetype");
  font-family: "Roboto";
  src: local("Roboto"), url(./assets/fonts/Roboto/Roboto-Regular.ttf);
  font-family: "RobotoSlab";
  src: local("RobotoSlab"),
    url(./assets/fonts/RobotoSlab/RobotoSlab-Regular.ttf);
}

#wrapper {
  overflow-x: hidden;
}

#sidebar-wrapper {
  min-height: 100vh;
  /*margin-left: -15rem;*/
  transition: margin 0.25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
  width: 3rem;
}

#page-content-wrapper {
  width: 100%;
}

body.sb-sidenav-toggled #wrapper #sidebar-wrapper {
  margin-left: 0;
}

.textSideMenu {
  display: none;
}

.iconSideMenu {
  display: inherit;
}

.add-form {
  margin-bottom: 40px;
}

.dyn-tbl{
  border-bottom-style: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.dyn-tbl-last{
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.dyn-tbl-first{
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-style: none;
}

#fab-button {
  width: 54px;
  height: 54px;
  border-radius: 100%;
  padding-top: 10px;
  padding-left: 10px;
  margin-bottom: 5%;
  margin-right: 5%;
  z-index: 999;
  /*box-shadow: 1px 1px 5px steelblue;*/
  box-shadow: 0 1px 2px 0 steelblue;
}

#fab-button:hover {
  animation: pulse 1.5s infinite;
  -webkit-animation: pulse 1.5s infinite;
}

.ng-valid[required],
.ng-valid.required {
  border-left: 5px solid #42a948; /* green */
}

.ng-invalid:not(form) {
  border-left: 5px solid #a94442; /* red */
}

@media (min-width: 768px) {
  #fab-button{
    width: 108;
    height: 108;
}
  #sidebar-wrapper {
    /*margin-left: 0;*/
    min-width: 10rem;
  }

  .iconSideMenu {
    display: none;
  }

  .iconSideBusinessMenu {
    display: inherit;
  }

  .textSideMenu {
    display: inherit;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  body.sb-sidenav-toggled #wrapper #sidebar-wrapper {
    margin-left: -10rem;
  }

  #sidebar-wrapper .list-group {
    width: 10rem;
  }

  #toast-wrapper {
    position: fixed;
    margin-bottom: 40%;
    z-index: 999;
    top: 80%;
    left: 50%;
    margin-left: -150px;
  }

  .textSideMenu.img-fluid {
    width: 150px;
  }
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

@media (min-width: 576px) {
  .border-sm-top {
    border-top: 1px solid #e3e7eb !important;
  }
  .border-sm-end {
    border-right: 1px solid #e3e7eb !important;
  }
  .border-sm-bottom {
    border-bottom: 1px solid #e3e7eb !important;
  }
  .border-sm-start {
    border-left: 1px solid #e3e7eb !important;
  }
  .border-sm-top-0 {
    border-top: 0 !important;
  }
  .border-sm-end-0 {
    border-right: 0 !important;
  }
  .border-sm-bottom-0 {
    border-bottom: 0 !important;
  }
  .border-sm-start-0 {
    border-left: 0 !important;
  }
  .border-sm-x {
    border-left: 1px solid #e3e7eb !important;
    border-right: 1px solid #e3e7eb !important;
  }
  .border-sm-y {
    border-top: 1px solid #e3e7eb !important;
    border-bottom: 1px solid #e3e7eb !important;
  }
  .border-sm {
    border-top: 1px solid #e3e7eb !important;
    border-bottom: 1px solid #e3e7eb !important;
    border-left: 1px solid #e3e7eb !important;
    border-right: 1px solid #e3e7eb !important;
  }
  .border-sm-0 {
    border-top: 0 !important;
    border-bottom: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
  }
}

@media (min-width: 768px) {
  .border-md-top {
    border-top: 1px solid #e3e7eb !important;
  }
  .border-md-end {
    border-right: 1px solid #e3e7eb !important;
  }
  .border-md-bottom {
    border-bottom: 1px solid #e3e7eb !important;
  }
  .border-md-start {
    border-left: 1px solid #e3e7eb !important;
  }
  .border-md-top-0 {
    border-top: 0 !important;
  }
  .border-md-end-0 {
    border-right: 0 !important;
  }
  .border-md-bottom-0 {
    border-bottom: 0 !important;
  }
  .border-md-start-0 {
    border-left: 0 !important;
  }
  .border-md-x {
    border-left: 1px solid #e3e7eb !important;
    border-right: 1px solid #e3e7eb !important;
  }
  .border-md-y {
    border-top: 1px solid #e3e7eb !important;
    border-bottom: 1px solid #e3e7eb !important;
  }
  .border-md {
    border-top: 1px solid #e3e7eb !important;
    border-bottom: 1px solid #e3e7eb !important;
    border-left: 1px solid #e3e7eb !important;
    border-right: 1px solid #e3e7eb !important;
  }
  .border-md-0 {
    border-top: 0 !important;
    border-bottom: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
  }
}

@media (min-width: 992px) {
  .border-lg-top {
    border-top: 1px solid #e3e7eb !important;
  }
  .border-lg-end {
    border-right: 1px solid #e3e7eb !important;
  }
  .border-lg-bottom {
    border-bottom: 1px solid #e3e7eb !important;
  }
  .border-lg-start {
    border-left: 1px solid #e3e7eb !important;
  }
  .border-lg-top-0 {
    border-top: 0 !important;
  }
  .border-lg-end-0 {
    border-right: 0 !important;
  }
  .border-lg-bottom-0 {
    border-bottom: 0 !important;
  }
  .border-lg-start-0 {
    border-left: 0 !important;
  }
  .border-lg-x {
    border-left: 1px solid #e3e7eb !important;
    border-right: 1px solid #e3e7eb !important;
  }
  .border-lg-y {
    border-top: 1px solid #e3e7eb !important;
    border-bottom: 1px solid #e3e7eb !important;
  }
  .border-lg {
    border-top: 1px solid #e3e7eb !important;
    border-bottom: 1px solid #e3e7eb !important;
    border-left: 1px solid #e3e7eb !important;
    border-right: 1px solid #e3e7eb !important;
  }
  .border-lg-0 {
    border-top: 0 !important;
    border-bottom: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
  }
}

@media (min-width: 1200px) {
  .border-xl-top {
    border-top: 1px solid #e3e7eb !important;
  }
  .border-xl-end {
    border-right: 1px solid #e3e7eb !important;
  }
  .border-xl-bottom {
    border-bottom: 1px solid #e3e7eb !important;
  }
  .border-xl-start {
    border-left: 1px solid #e3e7eb !important;
  }
  .border-xl-top-0 {
    border-top: 0 !important;
  }
  .border-xl-end-0 {
    border-right: 0 !important;
  }
  .border-xl-bottom-0 {
    border-bottom: 0 !important;
  }
  .border-xl-start-0 {
    border-left: 0 !important;
  }
  .border-xl-x {
    border-left: 1px solid #e3e7eb !important;
    border-right: 1px solid #e3e7eb !important;
  }
  .border-xl-y {
    border-top: 1px solid #e3e7eb !important;
    border-bottom: 1px solid #e3e7eb !important;
  }
  .border-xl {
    border-top: 1px solid #e3e7eb !important;
    border-bottom: 1px solid #e3e7eb !important;
    border-left: 1px solid #e3e7eb !important;
    border-right: 1px solid #e3e7eb !important;
  }
  .border-xl-0 {
    border-top: 0 !important;
    border-bottom: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
  }
}

.ol-custom-zoom {
  display: flex;
  justify-content: end;
  position: relative;
  z-index: 2;
}

.ol-custom-zoom-in, .ol-custom-zoom-out {
  width: 15px;
  height: 15px;
  padding: 0;
  margin:0;
  background-color: #39ca69;
  border-width: 0px;
  color: white;
  border-radius: 2px;
  margin-left: 1px;
  text-align: center;
  display: flex;
  align-content: center;
}

.ol-custom-zoom-in:hover, .ol-custom-zoom-out:hover{
  background-color: #0e953b;

}